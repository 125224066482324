export const Privacy = () => {
    return (
        <div className="fc cc fh80 fw80">
            <div className="fc sc fh fw scrolly">
                Privacy Policy At Massive Dragon Software Pty Ltd, we are
                committed to protecting the privacy of our customers and website
                visitors. This Privacy Policy explains how we collect, use, and
                safeguard the personal information you provide on our website.
                Information We Collect We may collect personal information such
                as your name, email address, postal address, phone number, and
                payment information when you purchase our products, subscribe to
                our services, or communicate with us. Additionally, we may
                collect non-personal information, such as your IP address,
                browser type, and operating system, for analytical purposes. How
                We Use Your Information We use the personal information you
                provide to process your orders, deliver our products and
                services, communicate with you about your account, and send you
                promotional materials or updates about our offerings. We may
                also use your information for market research and analysis to
                improve our products and services. Sharing Your Information We
                do not sell, trade, or rent your personal information to third
                parties. However, we may share your information with trusted
                third-party service providers who assist us in operating our
                business, delivering our products and services, or conducting
                other activities on our behalf. These third parties are
                obligated to keep your information confidential and secure. Data
                Security We implement reasonable physical, electronic, and
                managerial procedures to safeguard the personal information we
                collect from loss, misuse, unauthorized access, disclosure,
                alteration, and destruction. Cookies and Tracking Technologies
                Our website may use cookies and other tracking technologies to
                enhance your browsing experience and gather analytical data. You
                can control the use of cookies through your browser settings.
                Links to Third-Party Websites Our website may contain links to
                third-party websites. We are not responsible for the privacy
                practices or content of those sites. Children's Privacy Our
                website is not intended for children under the age of 13. We do
                not knowingly collect personal information from children without
                parental consent. Your Rights and Choices You may have the right
                to access, update, or delete your personal information, or to
                object to or restrict our processing of your information. If you
                have any questions or concerns about our Privacy Policy or the
                handling of your personal information, please contact us at
                [insert contact information]. Updates to this Privacy Policy We
                may update this Privacy Policy from time to time. The latest
                version of the Policy will be posted on our website, and we
                encourage you to review it periodically. By using our website
                and services, you consent to the collection, use, and sharing of
                your information as described in this Privacy Policy. Last
                updated: 16th March 2024
            </div>
        </div>
    )
}
