import { useEffect, useState } from 'react'
import { Privacy } from './Privacy'

export const App = () => {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)

    useEffect(() => {
        if (window.location.pathname === '/privacy-policy') {
            setShowPrivacyPolicy(true)
        }
    }, [])

    return (
        <div className="fc cc fh fw">
            {showPrivacyPolicy ? (
                <Privacy />
            ) : (
                <div className="fc cc fh fw">Massive Dragon Software</div>
            )}
        </div>
    )
}
